<template>
  <div class="oftenSet">
    <div class="oftenSet-left">
      <div class="oftenSet-left-title">
        <i class="el-icon-menu"></i>
        <span> 常用资料栏目</span>
      </div>
      <div class="oftenSet-left-cont">
        <div
          class="oftenSet-left-cont-list"
          @click="tabType(index)"
          :class="tabIndex == index ? 'oftenSet-left-cont-list-active' : ''"
          v-for="(item, index) in tabArr"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- <div class="oftenSet-left-btn">
        <el-button type="danger" @click="edit(1)">
          <i class="el-icon-circle-plus-outline el-icon--right"></i>
          <span>添加{{ tabArr[tabIndex].name }}</span>
        </el-button>
      </div> -->
    </div>
    <div class="oftenSet-btn">
      <el-button type="danger" @click="edit(1)">
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
        <span>添加{{ tabArr[tabIndex].name }}</span>
      </el-button>
    </div>
    <div class="oftenSet-right">
      <div class="oftenSet-right-cont">
        <el-table
          :data="tabArr[tabIndex].data"
          :border="$table('border')"
          :header-cell-style="{ background: $table('bgcolor') }"
          :stripe="$table('stripe')"
          style="width: 100%;"
        >
          <el-table-column prop="id" label="序号" :width="$table('width')">
            <template slot-scope="scope">
            <span>
              {{ scope.$index + 1 }}
            </span>
            <!-- <span v-else>
              {{ scope.$index + 1 + pages * tableData.length }}
            </span> -->
          </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="
              tabArr[tabIndex].name[0] + tabArr[tabIndex].name[1] + '名称'
            "
            width="180"
          >
          </el-table-column>
          <el-table-column prop="sort" label="排序" v-if="0">
            <!-- <template >
              <div class="btn-box">
                <i class="el-icon-bottom" ></i>
                <i class="el-icon-top"></i>
              </div>
            </template> -->
          </el-table-column>
          <el-table-column label="编辑">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(0, scope)"
                  >编辑</el-button
                >
                <el-button type="danger" plain size="small" @click="del(scope)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :title="type ? '新增资料' : '修改资料'"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
    >
      <div class="oftenSet-edit">
        <el-form
          label-position="left"
          label-width="120px"
          :model="formLabelAlign"
        >
          <el-form-item
            :label="
              tabArr[tabIndex].name[0] + tabArr[tabIndex].name[1] + '名称'
            "
          >
            <div class="oftenSet-edit-list">
              <el-input v-model="formLabelAlign.name"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="部门归类" v-if="0">
            <div class="oftenSet-edit-list">
              <el-select v-model="formLabelAlign.other" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="显示排序">
            <div class="oftenSet-edit-list">
              <el-input-number
                v-model="formLabelAlign.sort"
                :min="1"
                label="描述文字"
              ></el-input-number>
            </div>
          </el-form-item>
          <!-- <el-form-item label="状态">
            <div class="oftenSet-edit-list">
              <el-switch
                v-model="formLabelAlign.status"
                active-text="正常"
                inactive-text="失效"
              >
              </el-switch>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type" type="primary" @click="addList">添 加</el-button>
        <el-button v-if="!type" type="primary" @click="updateList"
          >保存修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      tabArr: [
        {
          id: "0",
          name: "员工称谓设置",
          href: [
            "/api/app/meiye/worktype/store",
            "/api/app/meiye/worktype/edit",
            "/api/app/meiye/worktype/del",
            "/api/app/meiye/worktype/add",
          ],
          data: [],
        },
        {
          id: "1",
          name: "部门分类设置",
          href: [
            "/api/app/meiye/shopdep/store",
            "/api/app/meiye/shopdep/edit",
            "/api/app/meiye/shopdep/del",
            "/api/app/meiye/shopdep/add",
          ],
          data: [],
        },
      ],
      tabIndex: 0,
      formLabelAlign: {},
      options: [
        {
          value: "0",
          label: "美发",
        },
        {
          value: "1",
          label: "美容",
        },
        {
          value: "2",
          label: "美甲",
        },
      ],
      fromDataIndex: -1,
    };
  },
  methods: {
    tabType(index) {
      this.tabIndex = index;
      this.getList(index);
    },
    /* 排序 */
    sorts(obj) {
      function sorts_fun(arr) {
        return function(a, b) {
          var v1 = a[arr];
          var v2 = b[arr];
          return v2 - v1;
        };
      }
      return obj.sort(sorts_fun("sort"));
    },
    /* 获取列表 index:类型 type:修改等操作重新请求接口 */
    getList(index, type) {
      if (!this.tabArr[index].href[0]) {
        this.$message.error("抱歉！功能升级维护中，请稍后再试!");
        return false;
      }

      if (this.tabArr[index].data.length && !type) {
        return false;
      }
      this.$axios({
        href: this.tabArr[index].href[0],
      }).then((res) => {
        let len = res.data.length;
        for (let i = 0; i < len; i++) {
          res.data[i].sort = res.data[i].sort || 1;
          res.data[i].status = true;
        }
        this.tabArr[index].data = this.sorts(res.data);
        console.log(res, this.sorts(res.data));
      });
    },
    /* 更新数据 */
    updateList(type, obj) {
      console.log(obj)
      let index = this.tabIndex;
      this.dialogVisible = false;
      // let ed_data = obj;

      // ed_data.sort = ed_data.sort? ed_data.sort - 0 + 1 : null;

      this.$axios({
        href: this.tabArr[index].href[1],
        data: this.tabArr[this.tabIndex].data[this.fromDataIndex],
      }).then((res) => {
        // if (res.data.data) {
        //   let len = res.data.data.length;
        //   for (let i = 0; i < len; i++) {
        //     res.data.data[i].sort = res.data.data[i].sort || 1;
        //     res.data.data[i].status = true;
        //   }
        // }
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.getList(index, 1);
        // this.tabArr[index].data = res.data.data;
        console.log(res);
      });
      // console.log(index,this.tabArr[this.tabIndex].data[this.fromDataIndex])
    },
    /* 删除数据 */
    delList() {
      let _this = this;
      let index = this.tabIndex;
      this.dialogVisible = false;
      this.$axios({
        href: this.tabArr[index].href[2],
        data: this.tabArr[this.tabIndex].data[this.fromDataIndex],
      }).then(() => {
        _this.$message({
          type: "success",
          message: "删除成功!",
        });
        _this.getList(index, 1);
      });
    },
    /* 添加数据 */
    addList() {
      let _this = this;
      this.dialogVisible = false;
      console.log(this.formLabelAlign);
      this.$axios({
        href: this.tabArr[this.tabIndex].href[3],
        data: this.formLabelAlign,
      }).then((res) => {
        _this.$message({
          type: "success",
          message: "添加成功!",
        });
        _this.getList(_this.tabIndex, 1);
        console.log(res);
      });
    },
    /* 添加更新 */
    edit(index, obj) {
      console.log(obj, this.tabArr[this.tabIndex], index);

      this.type = index;
      if (!index) {
        //   console.log(this.tabArr[this.tabIndex].data[obj.$index])
        this.formLabelAlign = this.tabArr[this.tabIndex].data[obj.$index];
        this.fromDataIndex = obj.$index;
      }
      if (index) {
        this.formLabelAlign = { index: 1, status: true };
        this.formLabelAlign.project_disc = 1;
        this.formLabelAlign.product_disc = 1;
        this.formLabelAlign.sort = 1;
      }

      this.dialogVisible = !this.dialogVisible;
    },
    del(obj) {
      console.log(obj);
      this.fromDataIndex = obj.$index;
      this.$confirm("此操作将永久删除该资料, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  },
  created() {
    this.getList(0);

    console.log(this.$table("bgcolor"));
  },
};
</script>

<style lang="scss" scoped>
.oftenSet {
  width: 100%;
  height: 100%;
  display: flex;
  &-left {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    margin: 0 1%;
    box-sizing: border-box;
    // padding-top: 10px;
    &-title {
      width: 100%;
      height: 48px;
      font-size: 16px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: Bold;
      color: #8088a8;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        margin-left: 10px;
      }
    }
    &-cont {
      flex: 1;
      width: 100%;
      // height: 85%;
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      &-list {
        width: 100%;
        height: 50px;
        border-radius: 25px;
        margin: 0 auto;
        display: flex;
        color: #8088a8;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
        border-bottom: 1px solid #eee;
      }
      &-list:hover {
        color: #fff;
        background: #8088a8;
      }
      &-list-active {
        background: #8088a8;
        color: #fff;
      }
      &-list-active:hover {
        background: #8088a8;
      }
      &-list:first-child {
        margin-top: 10px;
      }
      &-list:last-child {
        border: none;
      }
    }
    /* &-btn {
      width: 100%;
      height: 50px;
      overflow: hidden;
      button {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    } */
  }
  &-btn {
    position: fixed;
    top: 70px;
    right: 0;
    width: 180px;
    height: 47px;
    overflow: hidden;
    button {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
  &-right {
    width: 78%;
    height: 100%;
    // padding: 10px;
    box-sizing: border-box;
    // background: pink;
    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      &-logo {
        width: 100%;
        height: 180px;
        // background: red;
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          border-radius: 50%;
          width: 150px;
          height: 150px;
        }
      }
      &-list {
        width: 100%;
        height: 60px;

        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-text {
          width: 80%;
          height: 100%;
          padding-left: 30%;
          box-sizing: border-box;
          text-align: left;
          line-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-btn {
        position: absolute;
        top: 20px;
        right: 10px;
        width: 100px;
        height: 50px;
        //   background: red;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 100px;
    &-list {
      width: 72%;
      min-height: 40px;
      //   background: red;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>

<style moudle>
.oftenSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.oftenSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}
.btn-box i {
  cursor: pointer;
  margin: 0 5px;
}
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
</style>
